.gradient-fill:before {
    color: #fc73b4;
    background: -moz-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: -webkit-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: linear-gradient(to bottom, #9477b4 0%, #fc73b4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-title {
    text-align: center;
    margin-bottom: 3rem;
}

.section-title small {
    color: #998a9b;
}

.section-title h3 {
    font-family: Catamaran, Helvetica, Arial, sans-serif;
    color: #633991;
    font-size: 33px;
    font-weight: bold;
}

.card.features h4.card-title {
    font-family: Catamaran, Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #633991;
}

.ti-3x {
    font-size: 3rem;
}

.card.features {
    border: 0;
    border-radius: 3px;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease;
}

.card-appariance {
    border-radius: 4px;
    height: 115px;
}

@media (max-width:991px) {
    .card.features {
        margin-bottom: 2rem;
    }
    [class^="col-"]:last-child .card.features {
        margin-bottom: 0;
    }
}

/* .card.features:before {
    content: "";
    position: absolute;
    width: 3px;
    color: #fc73b4;
    background: -moz-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: -webkit-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: linear-gradient(to bottom, #9477b4 0%, #fc73b4 100%);
    top: 0;
    bottom: 0;
    left: 0;
} */