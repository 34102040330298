.bg-gradient {
    background-image: -moz-linear-gradient(135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: -webkit-linear-gradient(135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: -ms-linear-gradient(135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: linear-gradient(135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
}

.tagline-pink {
    font-size: 23px;
    font-weight: 300;
    color: #ffb8f6;
    max-width: 800px;
    margin: 0 auto;
}

.call-to-action {
    text-align: center;
    color: #FFF;
    margin: 3rem 0;
}

.call-to-action .box-icon {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    transform: scale(0.85);
    margin-bottom: 2.5rem;
}

.call-to-action h2 {
    color: #FFF;
}

.call-to-action .tagline {
    font-size: 16px;
    font-weight: 300;
    color: #ffb8f6;
    max-width: 650px;
    margin: 0 auto;
}

.btn {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.375rem 1.35rem;
    transition: all 0.3s ease;
}

.btn-outline-light:hover {
    color: #d6619c;
}

.btn-light {
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.26);
    font-size: 12px;
    font-weight: bold;
    color: #633991;
    margin: 0.5rem;
    padding: 0.7rem 1.6rem;
    line-height: 1.8;
}

.btn-light img {
    margin-right: 0.4rem;
    vertical-align: text-bottom;
}