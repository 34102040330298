.circle-icon {
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
    padding: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 1.5rem;
    background-color: #FFF;
    color: #f5378e;
    font-size: 48px;
    text-align: center;
    line-height: 80px;
    font-weight: 300;
    transition: all 0.3s ease;
}

@media (max-width:992px) {
    .circle-icon {
        width: 70px;
        height: 70px;
        font-size: 28px;
        line-height: 50px;
    }
}

.ui-steps {
    width: 100%;
}

.ui-steps li:hover .circle-icon {
    background-image: -moz-linear-gradient( 122deg, #e6388e 0%, #fb378e 100%);
    background-image: -webkit-linear-gradient( 122deg, #e6388e 0%, #fb378e 100%);
    background-image: -ms-linear-gradient( 122deg, #e6388e 0%, #fb378e 100%);
    background-image: linear-gradient( 122deg, #e6388e 0%, #fb378e 100%);
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.09);
    color: #FFF;
}

.ui-steps li {
    padding: 15px 0;color: #959094;
}

.ui-steps li:not(:last-child) {
    border-bottom: 1px solid #f8e3f0;
}

.ui-steps li .media-body h5 {
    font-size: 28px;
    font-weight: 300;
    color: #633991;
    margin-bottom: 0.7rem;
}

.ui-steps li .media-body p {
    color: #4e4e4e;
}