.f-20 {
    font-size: 20px;
}

.custom-flex {
    display: flex;
}

@media (max-width: 768px) {
    .custom-flex {
        display: flex;
        flex-direction: column;
    }
}