.signup-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1.2rem;
  color: #fd5e6e;
}

.signup-success {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1.2rem;
  color: #7aff99;
}
