.navbar.bg-light {
    background: transparent !important;
}

.navbar .navbar-brand {
    font-family: 'Catamaran', sans-serif;
    text-transform: lowercase !important;
    font-size: 24pt;
    padding: 1rem 0;
    transition: all 0.3s ease;
    color: #fff !important;
}

.nav-item .nav-link {
    font-family: 'Muli', sans-serif !important;
    text-transform: uppercase !important;
    font-size: 16px !important;
    font-weight: bold;
    color: #fff !important;
}
.nav-link-route{
    font-family: Muli,sans-serif!important;
    text-transform: uppercase!important;
    font-size: 16px!important;
    font-weight: 700;
    color: #fff!important;
}
.space-nav{
    justify-content: space-between !important;
    align-items: center;
}