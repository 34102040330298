html,
body,
.main,
#root {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.title-donation {
	font-weight: 900;
	color: #633991;
}

.paragraph-donation {
	font-size: 16px;
	color: #3b3b3b;
	margin-top: 15px;
}

.btn-donation {
	border-style: none;
	background-color: #6b25b0;
	color: white;
	border: none;
	border-radius: 30px;
	text-align: center;
	padding: 10px;
	font-size: 16px;
	font-weight: 800;
}

.btn-donation:hover {
	background-color: rgb(61, 15, 107);
}

.btn-donation:disabled {
	background-color: rgb(187, 180, 194);
}

.btn-donation-outline {
	border-style: none;
	background-color: white;
	color: #6b25b0;
	border: #6b25b0 1px solid;
	border-radius: 30px;
	text-align: center;
	padding: 10px;
	font-size: 16px;
	font-weight: 800;
}

.btn-donation-outline:hover {
	border-style: none;
	background-color: rgb(61, 15, 107);
	color: white;
}

.btn-amount {
	border: none;
	height: 36px;
	width: 100%;
	border-radius: 4px;
	text-align: center;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 20px;
	font-weight: 800;
	background: linear-gradient(122deg, rgba(111, 44, 227, 1) 0%, rgba(138, 21, 126, 1) 100%);
	color: white;

	&.btn-noselect {
		background: linear-gradient(122deg, #9a6ceb 0%, #e48ddb 100%);
	}
}

.btn-amount:hover {
	border: none;
	height: 36px;
	width: 100%;
	border-radius: 4px;
	text-align: center;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 20px;
	font-weight: 800;
	background: linear-gradient(122deg, #42168d 0%, #580e51 100%);
	color: white;
}

.btn-goDonation {
	border: none;
	height: 36px;
	width: 100%;
	border-radius: 4px;
	text-align: center;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 20px;
	font-weight: 500;
	background: linear-gradient(122deg, rgba(111, 44, 227, 1) 0%, rgba(138, 21, 126, 1) 100%);
	color: white;
}

.btn-goDonation:hover {
	text-decoration: none;
	border: none;
	height: 36px;
	width: 100%;
	border-radius: 4px;
	text-align: center;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 20px;
	font-weight: 500;
	background: linear-gradient(122deg, #42168d 0%, #580e51 100%);
	color: white;
}

.btn-close {
	border: 1px solid #42168d !important;
	height: 36px !important;
	width: 100% !important;
	border-radius: 4px !important;
	text-align: center !important;
	align-items: center !important;
	display: flex !important;
	justify-content: center !important;
	font-size: 16px !important;
	font-weight: 500 !important;
	background: white !important;
	color: #42168d !important;
	text-transform: none!important;
}

.custom-card {
	background-color: white;
	border: none;
	box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
	transition: all 0.3s ease;
}

.error {
	color: #db2269;
	font-size: 0.625em;
	display: relative;
	&-payment {
		font-size: 1em;
		color: #db2269;
	}
}

.payment-input {
	border: 1px solid #ced4da;
	height: 34px;
	border-radius: 4px;
	padding: 8px;
	height: calc(1.5em + .75rem + 2px);
	font-size: 1rem !important;
	font-weight: 400 !important;
}

.min-h {
	min-height: 100%;
}

.spin-animation {
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotateZ(0);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

@media only screen and (width: 426px) and (width: 0) {
	.padding-custom {
		padding: 0 !important;
	}
}
