@import url(https://fonts.googleapis.com/css?family=Catamaran|Muli);
html,
body,
.main,
#root {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.title-donation {
  font-weight: 900;
  color: #633991; }

.paragraph-donation {
  font-size: 16px;
  color: #3b3b3b;
  margin-top: 15px; }

.btn-donation {
  border-style: none;
  background-color: #6b25b0;
  color: white;
  border: none;
  border-radius: 30px;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 800; }

.btn-donation:hover {
  background-color: #3d0f6b; }

.btn-donation:disabled {
  background-color: #bbb4c2; }

.btn-donation-outline {
  border-style: none;
  background-color: white;
  color: #6b25b0;
  border: #6b25b0 1px solid;
  border-radius: 30px;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 800; }

.btn-donation-outline:hover {
  border-style: none;
  background-color: #3d0f6b;
  color: white; }

.btn-amount {
  border: none;
  height: 36px;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(122deg, #6f2ce3 0%, #8a157e 100%);
  color: white; }
  .btn-amount.btn-noselect {
    background: linear-gradient(122deg, #9a6ceb 0%, #e48ddb 100%); }

.btn-amount:hover {
  border: none;
  height: 36px;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(122deg, #42168d 0%, #580e51 100%);
  color: white; }

.btn-goDonation {
  border: none;
  height: 36px;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  background: linear-gradient(122deg, #6f2ce3 0%, #8a157e 100%);
  color: white; }

.btn-goDonation:hover {
  text-decoration: none;
  border: none;
  height: 36px;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  background: linear-gradient(122deg, #42168d 0%, #580e51 100%);
  color: white; }

.btn-close {
  border: 1px solid #42168d !important;
  height: 36px !important;
  width: 100% !important;
  border-radius: 4px !important;
  text-align: center !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  background: white !important;
  color: #42168d !important;
  text-transform: none !important; }

.custom-card {
  background-color: white;
  border: none;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease; }

.error {
  color: #db2269;
  font-size: 0.625em;
  display: relative; }
  .error-payment {
    font-size: 1em;
    color: #db2269; }

.payment-input {
  border: 1px solid #ced4da;
  height: 34px;
  border-radius: 4px;
  padding: 8px;
  height: calc(1.5em + .75rem + 2px);
  font-size: 1rem !important;
  font-weight: 400 !important; }

.min-h {
  min-height: 100%; }

.spin-animation {
  animation: spin 1s linear infinite; }

@keyframes spin {
  0% {
    transform: rotateZ(0); }
  100% {
    transform: rotateZ(360deg); } }

@media only screen and (width: 426px) and (width: 0) {
  .padding-custom {
    padding: 0 !important; } }

.text-header{
  font-family: Catamaran, Helvetica, Arial, sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 200;
  letter-spacing: 1px;
  line-height: 45px;
  text-transform: none;
  color: #333333;
}

.text-content {
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  text-transform: none;
  color: #777777;
}
li.my-4{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.media-body p{
  margin-bottom: 0px;
}
.align-items-center ul{
  margin-bottom: 0px;
}

.font-home {
  font-size: 1.2rem!important;
}
.navbar.bg-light {
    background: transparent !important;
}

.navbar .navbar-brand {
    font-family: 'Catamaran', sans-serif;
    text-transform: lowercase !important;
    font-size: 24pt;
    padding: 1rem 0;
    transition: all 0.3s ease;
    color: #fff !important;
}

.nav-item .nav-link {
    font-family: 'Muli', sans-serif !important;
    text-transform: uppercase !important;
    font-size: 16px !important;
    font-weight: bold;
    color: #fff !important;
}
.nav-link-route{
    font-family: Muli,sans-serif!important;
    text-transform: uppercase!important;
    font-size: 16px!important;
    font-weight: 700;
    color: #fff!important;
}
.space-nav{
    justify-content: space-between !important;
    align-items: center;
}
.gradient-fill:before {
    color: #fc73b4;
    background: linear-gradient(to bottom, #9477b4 0%, #fc73b4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-title {
    text-align: center;
    margin-bottom: 3rem;
}

.section-title small {
    color: #998a9b;
}

.section-title h3 {
    font-family: Catamaran, Helvetica, Arial, sans-serif;
    color: #633991;
    font-size: 33px;
    font-weight: bold;
}

.card.features h4.card-title {
    font-family: Catamaran, Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #633991;
}

.ti-3x {
    font-size: 3rem;
}

.card.features {
    border: 0;
    border-radius: 3px;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease;
}

.card-appariance {
    border-radius: 4px;
    height: 115px;
}

@media (max-width:991px) {
    .card.features {
        margin-bottom: 2rem;
    }
    [class^="col-"]:last-child .card.features {
        margin-bottom: 0;
    }
}

/* .card.features:before {
    content: "";
    position: absolute;
    width: 3px;
    color: #fc73b4;
    background: -moz-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: -webkit-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: linear-gradient(to bottom, #9477b4 0%, #fc73b4 100%);
    top: 0;
    bottom: 0;
    left: 0;
} */
.signup-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1.2rem;
  color: #fd5e6e;
}

.signup-success {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1.2rem;
  color: #7aff99;
}

.section {
    padding: 80px 0;
}

.light-bg {
    background-color: #faf6fb;
}
.circle-icon {
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
    padding: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 1.5rem;
    background-color: #FFF;
    color: #f5378e;
    font-size: 48px;
    text-align: center;
    line-height: 80px;
    font-weight: 300;
    transition: all 0.3s ease;
}

@media (max-width:992px) {
    .circle-icon {
        width: 70px;
        height: 70px;
        font-size: 28px;
        line-height: 50px;
    }
}

.ui-steps {
    width: 100%;
}

.ui-steps li:hover .circle-icon {
    background-image: linear-gradient( 122deg, #e6388e 0%, #fb378e 100%);
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.09);
    color: #FFF;
}

.ui-steps li {
    padding: 15px 0;color: #959094;
}

.ui-steps li:not(:last-child) {
    border-bottom: 1px solid #f8e3f0;
}

.ui-steps li .media-body h5 {
    font-size: 28px;
    font-weight: 300;
    color: #633991;
    margin-bottom: 0.7rem;
}

.ui-steps li .media-body p {
    color: #4e4e4e;
}
.bg-gradient {
    background-image: linear-gradient(135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
}

.tagline-pink {
    font-size: 23px;
    font-weight: 300;
    color: #ffb8f6;
    max-width: 800px;
    margin: 0 auto;
}

.call-to-action {
    text-align: center;
    color: #FFF;
    margin: 3rem 0;
}

.call-to-action .box-icon {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    transform: scale(0.85);
    margin-bottom: 2.5rem;
}

.call-to-action h2 {
    color: #FFF;
}

.call-to-action .tagline {
    font-size: 16px;
    font-weight: 300;
    color: #ffb8f6;
    max-width: 650px;
    margin: 0 auto;
}

.btn {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.375rem 1.35rem;
    transition: all 0.3s ease;
}

.btn-outline-light:hover {
    color: #d6619c;
}

.btn-light {
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.26);
    font-size: 12px;
    font-weight: bold;
    color: #633991;
    margin: 0.5rem;
    padding: 0.7rem 1.6rem;
    line-height: 1.8;
}

.btn-light img {
    margin-right: 0.4rem;
    vertical-align: text-bottom;
}
header {
    /* background-image: url('/images/header_bg2.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover; */
    padding: 100px 0 0;
    text-align: center;
    color: #FFF;
    margin-top: -96px;
}

header h1 {
    font-family: Catamaran, Helvetica, Arial, sans-serif;
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -1px;
    margin-bottom: 1.5rem;
}

.tagline {
    font-family: Muli, Helvetica, Arial, sans-serif;
    font-size: 23px;
    font-weight: 300;
    color: #fff;
    max-width: 800px;
    margin: 0 auto;
}
.section{
    background-color: #fff;
}
.section.light-bg {
    background-color: #faf6fb;
}
.perspective-phone{
    z-index: 900 !important;
}
.img-holder {
    height: 0;
    padding-bottom: 38%;
    overflow: hidden;
}

@media (max-width:1200px) {
    .img-holder {
        padding-bottom: 50%;
    }
}

@media (max-width:767px) {
    .tagline {
        font-size: 17px;
    }
    .img-holder {
        padding-bottom: 100%;
    }
}
.phrase {
    font-size: 24px;
    color: #3B3B3B;
    margin-top: 17px;
}

.section {
    padding: 80px 0;
    font-family: Catamaran, Helvetica, Arial, sans-serif;
}

.custom-height {
    height: 400px;
}

.section h3 {
    color: #633991;
    font-size: 33px;
    font-weight: bold;
}

.f-20 {
    font-size: 20px;
}

.custom-flex {
    display: flex;
}

@media (max-width: 768px) {
    .custom-flex {
        display: flex;
        flex-direction: column;
    }
}
.perspective-phone {
    position: relative;
    z-index: -1;
}

@media (min-width: 992px) {
    .perspective-phone {
        margin-top: -225px;
    }
}
body{
    background-image: url('/images/header_bg2.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
