header {
    /* background-image: url('/images/header_bg2.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover; */
    padding: 100px 0 0;
    text-align: center;
    color: #FFF;
    margin-top: -96px;
}

header h1 {
    font-family: Catamaran, Helvetica, Arial, sans-serif;
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -1px;
    margin-bottom: 1.5rem;
}

.tagline {
    font-family: Muli, Helvetica, Arial, sans-serif;
    font-size: 23px;
    font-weight: 300;
    color: #fff;
    max-width: 800px;
    margin: 0 auto;
}
.section{
    background-color: #fff;
}
.section.light-bg {
    background-color: #faf6fb;
}
.perspective-phone{
    z-index: 900 !important;
}
.img-holder {
    height: 0;
    padding-bottom: 38%;
    overflow: hidden;
}

@media (max-width:1200px) {
    .img-holder {
        padding-bottom: 50%;
    }
}

@media (max-width:767px) {
    .tagline {
        font-size: 17px;
    }
    .img-holder {
        padding-bottom: 100%;
    }
}