@import url('https://fonts.googleapis.com/css?family=Catamaran|Muli');

.text-header{
  font-family: Catamaran, Helvetica, Arial, sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 200;
  letter-spacing: 1px;
  line-height: 45px;
  text-transform: none;
  color: #333333;
}

.text-content {
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  text-transform: none;
  color: #777777;
}
li.my-4{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.media-body p{
  margin-bottom: 0px;
}
.align-items-center ul{
  margin-bottom: 0px;
}

.font-home {
  font-size: 1.2rem!important;
}