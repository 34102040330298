.phrase {
    font-size: 24px;
    color: #3B3B3B;
    margin-top: 17px;
}

.section {
    padding: 80px 0;
    font-family: Catamaran, Helvetica, Arial, sans-serif;
}

.custom-height {
    height: 400px;
}

.section h3 {
    color: #633991;
    font-size: 33px;
    font-weight: bold;
}
